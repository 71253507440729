export default function createPackageTranslations(translations) {
  if (!translations) {
    return {};
  }

  return Object.entries(translations)
    .reduce((acc, [key, localeTranslation]) => {
      acc[key] = {
        name: localeTranslation?.name ?? '',
        description: localeTranslation?.description ?? '',
        metaDescription: localeTranslation?.meta_description ?? '',
        metaTitle: localeTranslation?.meta_title ?? '',
        shortDescription: localeTranslation?.short_description ?? '',
        reviews: localeTranslation?.reviews ?? '',
      };

      return acc;
    }, {});
}
