import createPackageContent from './createPackageContent';
import createPackageTranslations from './CreatePackageTranslations';

export default function createPackageFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    type: data.type ?? 'auto',
    network: data.network ? {
      id: data.network.id,
      name: data.network.name,
    } : null,
    defaultCost: data.default_cost,
    difference: data.difference,
    isArchived: data.is_archived,
    isEnabled: data.is_enabled,
    packageContents: (data.package_contents || []).map(createPackageContent),
    actions: data.actions,
    fixedCost: data.fixed_cost,
    discountPercent: data.discount_percent,
    price: data.fixed_cost || parseInt((data.discount_percent) * 100, 10),
    translations: createPackageTranslations(data.translations),
    totalCost: data.total_cost,
    sync: data.sync,
    provider: data.provider ? {
      id: data.provider.id,
      name: data.provider.name,
    } : null,
  };
}
