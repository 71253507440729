import createPackageProviderFromResource from '@/classes/createPackageProvider';
import urlFormatter from '../url-formatter';
import http from '../http';

const getURL = urlFormatter({
  list: '/api/v1/admin/providers-packages',
  show: '/api/v1/admin/providers-packages/{id}',
});

export default {
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  show(id) {
    const endpoint = getURL('show', { id });

    return http.get(endpoint)
      .then((response) => createPackageProviderFromResource(response.data.data));
  },
  getURL,
};
