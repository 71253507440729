export default function createPackageProviderFromResource(data) {
  return {
    id: data.id,
    name: data.name,
    isArchived: data.is_archived,
    fixedCost: data.fixed_cost,
    discountPercent: data.discount_percent,
    type: data.type,
    packageContents: data.package_contents.map((packageContent) => ({
      id: packageContent.id,
      defaultPrice: packageContent.default_price,
      maxQuantity: packageContent.max_quantity,
      minQuantity: packageContent.min_quantity,
      name: packageContent.name,
      pricingPer: packageContent.pricing_per,
      quantityStep: packageContent.quantity_step,
      quantity: packageContent.quantity,
      isDisplayable: packageContent.is_displayable,
    })),
    network: {
      id: data.network.id,
      name: data.network.name,
    },
    translations: data.translations,
  };
}
